<template>
  <div></div>
</template>

<script>
export default {
  name: "SweetAlert",
  props: {},
  data() {
    return {};
  },

  methods: {
    getSweetAlert(values) {
      let data = this.$store.state.SweetAlert.sweetAlerts;
      if (data) {
        this.$swal({
          ...values,
          showConfirmButton: data.icon === "error" ? true : false,
          timer: data.icon === "error" ? "" : 1500,
          timerProgressBar: true,
          toast: data.icon == "error" ? false : true,
          customClass: "swal-wide",
          position: "top",
          padding: 10,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      }
    },
  },

  created() {},
  mounted() {},

  computed: {
    sweetAlerts: {
      get() {
        return this.$store.getters.getSweetAlert;
      },

      set(value) {
        this.getSweetAlert({ ...value });
      },
    },
  },
  watch: {
    sweetAlerts: {
      handler() {
        this.getSweetAlert(this.sweetAlerts);
      },
      update() {
        this.getSweetAlert(this.sweetAlerts);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.swal-wide {
  width: 850px !important;
}
</style>
